

<template>
  <div class="component-upload-image">
    <el-dialog :close-on-click-modal="false" :title="td('库存明细图片上传')" :visible.sync="show" @close="close" width="820px"
      append-to-body>
      <el-upload class="upload-demo" :action="uploadImgUrl" drag multiple list-type="picture-card"
        :on-success="handleUploadSuccess" :before-upload="handleBeforeUpload" :limit="limit" :on-error="handleUploadError"
        :on-exceed="handleExceed" accept=".png,.jpeg,.jpg" name="file" :on-remove="handleRemove" :show-file-list="true"
        :headers="headers" :file-list="fileList" :on-preview="handlePictureCardPreview">
        <i class="el-icon-upload"></i>
        <span class="up-txt">将文件拖到此处，或<em>点击上传</em> </span>
      </el-upload>

      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        {{ td("请上传") }}
        <template v-if="fileSize">
          {{ td("大小不超过") }} <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          {{ td("格式为") }}
          <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
        </template>
        {{ td("的文件") }}
      </div>

      <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="td('预览')" width="800" height="800"
        append-to-body>
        <img :src="dialogImageUrl" style="display: block; width: 600px; height: 600px; margin: 0 auto" />
      </el-dialog>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">{{
          td("提 交")
        }}</el-button>
        <el-button @click="close">{{ td("取 消") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { warehous, listFile, delFile } from "@/api/tms/file";

export default {
  props: {
    row: Object,
    show: Boolean,
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 10,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      // https://api.6pl.com.cn/file/upload
      uploadImgUrl: "https://api.6pl.com.cn/file/upload", // 线上上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      //原始图片
      fileList: [],
      //将要上传到服务器的图片
      fileListImage: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(",");
          // 然后将数组转为对象数组
          this.fileList = list.map((item) => {
            if (typeof item === "string") {
              item = { name: item, url: item };
            }
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initImg();
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    //照片路径初始化
    initImg() {
      listFile({ expressId: this.row.expressId, recordType: "5568" }).then(
        (res) => {
          res.rows.forEach((el) => {
            this.fileList.push({
              name: el.fileName,
              url: el.fileUrl,
              fileId: el.fileId,
            });
          });
        }
      );
    },
    //提交照片路径
    submitFileForm() {
      if (this.fileListImage.length == 0)
        return this.msgWarning(this.td("请先上传图片"));
      let files = [];
      this.fileListImage.forEach((el) => {
        let obj = {};
        if (el.fileId != undefined && el.fileId != null && el.fileId != "") {
          obj.fileId = el.fileId;
        }
        obj.fileUrl = el.url;
        obj.fileName = el.name;
        obj.expressId = this.row.expressId;
        obj.expressTwo = this.row.receiptId;
        files.push(obj);
      });
      warehous(files).then((res) => {
        if (res.code == 200) {
          this.$message.success("上传成功");
        } else {
          this.$message.error("失败");
        }
        this.close();
      });
    },

    close() {
      this.$emit("close");
    },
    // 删除图片
    handleRemove(file, fileList) {
      if (
        file.fileId != undefined &&
        file.fileId != null &&
        file.fileId != ""
      )
      //删除已经上传数据库的图片
      {
        delFile(file.fileId).then((res) => {
          if (res.code == 200) {
            this.$message.success(this.td("删除成功"));
          } else {
            this.$message.error(this.td("删除失败"));
          }
        });
      }
      //删除本地缓存的图片
      else {
        this.fileListImage.splice(this.fileListImage.findIndex((item) => item.name == file.response.data.name), 1);
      }
    },
    // 上传成功回调
    async handleUploadSuccess(res) {
      await this.fileListImage.push({ name: res.data.name, url: res.data.url });
      this.loading.close();
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error(
          `文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`
        );
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传图片大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError() {
      this.$message({
        type: "error",
        message: this.td("上传失败"),
      });
      this.loading.close();
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    },
  },
};
</script>
<!--<style scoped lang="scss">-->
<!--// .el-upload&#45;&#45;picture-card 控制加号部分-->
<!--::v-deep.hide .el-upload&#45;&#45;picture-card {-->
<!--  display: none;-->
<!--}-->

<!--// 去掉动画效果-->
<!--::v-deep .el-list-enter-active,-->
<!--::v-deep .el-list-leave-active {-->
<!--  transition: all 0s;-->
<!--}-->

<!--::v-deep .el-list-enter,-->
<!--.el-list-leave-active {-->
<!--  opacity: 0;-->
<!--  transform: translateY(0);-->
<!--}-->
<!--</style>-->


<style scoped>
/deep/ .el-upload {
  width: 100%;
}

/deep/ .el-upload .el-upload-dragger {
  width: 100%;
}
</style>

import request from '@/utils/request'

// 查询签收图片上传列表
export function listFile(query) {
  return request({
    url: '/tms/file/list',
    method: 'get',
    params: query
  })
}

// 查询签收图片上传详细
export function getFile(fileId) {
  return request({
    url: '/tms/file/' + fileId,
    method: 'get'
  })
}

// 查询签收图片上传详细
export function warehous(data) {
  return request({
    url: '/tms/file/warehous',
    method: 'post',
    data:data
  })
}


// 新增签收图片上传
export function addFile(data) {
  return request({
    url: '/tms/file',
    method: 'post',
    data: data
  })
}

// 修改签收图片上传
export function updateFile(data) {
  return request({
    url: '/tms/file',
    method: 'put',
    data: data
  })
}

// 删除签收图片上传
export function delFile(fileId) {
  return request({
    url: '/tms/file/' + fileId,
    method: 'delete'
  })
}

/** 根据单号和记录类型查询图片路径 */
export function viewImg(expressId, recordType) {
  return request({
    url: '/tms/file/viewImg/' + expressId + '/' + recordType,
    method: 'get'
  })
}

/**
 * 根据派送单号查询图片路径
 */
export function viewImgByOrderSignId(orderSignId) {
  return request({
    url: '/tms/file/viewImgByOrderSignId/' + orderSignId,
    method: 'get'
  })
}

/**
 * 根据派送单号查询图片路径
 */
export function selectExpressId(expressId) {
  return request({
    url: '/tms/file/expressId/' + expressId,
    method: 'get'
  })
}


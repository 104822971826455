import request from '@/utils/request'
import { getToken } from "@/utils/auth"


// 查询客户资料列表
export function listCustomer(query) {
  return request({
    url: '/system/customer/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

/** 查询客户资料 */
export function selectCustomerDict() {
  return request({
    url: '/system/customer/selectCustomerDict',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}

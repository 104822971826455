import request from '@/utils/request'

// 查询国家列表列表
export function listCountry(query) {
  return request({
    url: '/tms/country/list',
    method: 'get',
    params: query
  })
}



// 查询国家列表详细
export function getCountry(countryId) {
  return request({
    url: '/tms/country/' + countryId,
    method: 'get'
  })
}

// 新增国家列表
export function addCountry(data) {
  return request({
    url: '/tms/country',
    method: 'post',
    data: data
  })
}

// 修改国家列表
export function updateCountry(data) {
  return request({
    url: '/tms/country',
    method: 'put',
    data: data
  })
}

// 删除国家列表
export function delCountry(countryId) {
  return request({
    url: '/tms/country/' + countryId,
    method: 'delete'
  })
}

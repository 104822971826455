import request from '@/utils/request'

// 查询机构列表
export function listSysSite(query) {
  return request({
    url: '/system/SysSite/list',
    method: 'get',
    params: query
  })
}
// 查询全部的机构列表
export function listSysSites(query) {
  return request({
    url: '/system/SysSite/lists',
    method: 'get',
    params: query
  })
}
// 查询机构(仓库类型)列表
export function wmsListSysSite(query) {
  return request({
    url: '/system/SysSite/wmsList',
    method: 'get',
    params: query
  })
}

// 查询机构详细
export function getSysSite(sitecode) {
  return request({
    url: '/system/SysSite/' + sitecode,
    method: 'get'
  })
}

// 新增机构
export function addSysSite(data) {
  return request({
    url: '/system/SysSite',
    method: 'post',
    data: data
  })
}

// 修改机构
export function updateSysSite(data) {
  return request({
    url: '/system/SysSite',
    method: 'put',
    data: data
  })
}
// 修改仓库
export function updateDepot(data) {
  return request({
    url: '/system/SysSite/editDepot',
    method: 'put',
    data: data
  })
}

// 修改机构状态
export function updateSiteStatus(sitecode,status) {
  const data = {
    sitecode,
    status
  }
  return request({
    url: '/system/SysSite/changeStatus',
    method: 'put',
    data: data
  })
}


// 删除机构
export function delSysSite(sitecode) {
  return request({
    url: '/system/SysSite/' + sitecode,
    method: 'delete'
  })
}
// 树结构
export function treeList() {
  return request({
    url: '/system/SysSite/tree',
    method: 'get',
  })
}
// 树结构--不进行数据隔离
export function treeList_all() {
  return request({
    url: '/system/SysSite/tree_all',
    method: 'get',
  })
}
// 全部网点树结构
export function sysSiteAll() {
  return request({
    url: '/system/SysSite/all_tree',
    method: 'get',
  })
}
// 节点搜索  nodeSearch
export function nodeSearch(query) {
  return request({
    url: '/system/SysSite/nodeSearch',
    method: 'get',
    params: query
  })
}
// 获取所属区域下拉框
export function tmsAreaSearch() {
  return request({
    url: '/tms/area/Arealist',
    method: 'get'
  })
}

// 树结构
export function getCountryList() {
  return request({
    url: '/system/region/country',
    method: 'get',
  })
}

/** 根据sizecode查询所有的节点 */
export function selectAllNodeBySizecode(sizecode) {
  return request({
    url: '/system/SysSite/selectAllNodeBySizecode/' + sizecode,
    method: 'get'
  })
}

<template>
  <div class="app-container">
    <!--详情-->
    <el-dialog
      :title="td('详情')"
      :visible.sync="showDetail"
      width="1410px"
      :close-on-click-modal="false"
      @close="showDetails"
    >
      <!--      <el-row>-->
      <!--        <el-col :span="12">库位码: <el-input  v-model="remarkData.locationCode" ></el-input></el-col>-->
      <!--        <el-col :span="12">packSubId: <el-input  v-model="remarkData.packSubId" ></el-input></el-col>-->
      <!--      </el-row>-->
      <el-table :data="details">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          width="150px"
          property="cuscode"
          :label="td('客户代号')"
        ></el-table-column>
        <el-table-column
          width="150px"
          property="expressId"
          :label="td('快递单号')"
        ></el-table-column>
        <el-table-column
          width="150px"
          property="receiptId"
          :label="td('包裹号')"
        ></el-table-column>
        <el-table-column property="goodsType" :label="td('类别')" width="130px">
          <template slot-scope="scope">
            <el-select v-model="scope.row.goodsType">
              <el-option
                v-for="dict in tf('GOODS_TYPE')"
                :key="dict.detailId"
                :label="dict.langTranslate"
                :value="dict.detailId"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column property="goodsId" width="100px" :label="td('品名')">
          <template slot-scope="scope">
            <el-cascader
              v-model="scope.row.goodsCascade"
              :options="goodsTypeList"
              @change="goodsIdChange1(scope.row)"
            >
            </el-cascader>
          </template>
        </el-table-column>
        <!--        <el-table-column property="goodsName" label="品名" width="120px">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-input-->
        <!--              @change="calculateV(scope.row)"-->
        <!--              v-model="scope.row.goodsName"-->
        <!--            ></el-input>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          property="qtyInput"
          :label="td('商品数量')"
          width="90px"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.qtyInput"></el-input>
          </template>
        </el-table-column>
        <el-table-column property="itemUnit" width="100px" :label="td('单位')">
          <template slot-scope="scope">
            <el-select v-model="scope.row.itemUnit">
              <el-option
                v-for="dict in tf('ITEM_UNIT')"
                :key="dict.detailId"
                :label="dict.langTranslate"
                :value="dict.detailId"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column property="length" :label="td('长 cm')" width="120px">
          <template slot-scope="scope">
            <el-input
              @change="calculateV(scope.row, 1)"
              v-model="scope.row.vlength"
              ref="input_len"
              @keyup.enter.native="
                handleNext(scope.row.receiptId + 'input_wid')
              "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column property="width" :label="td('宽 cm')" width="120px">
          <template slot-scope="scope">
            <el-input
              @change="calculateV(scope.row, 1)"
              v-model="scope.row.vwidth"
              :ref="scope.row.receiptId + 'input_wid'"
              @keyup.enter.native="
                handleNext(scope.row.receiptId + 'input_hei')
              "
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column property="height" :label="td('高 cm')" width="120px">
          <template slot-scope="scope">
            <el-input
              @change="calculateV(scope.row, 1)"
              v-model="scope.row.vheight"
              :ref="scope.row.receiptId + 'input_hei'"
              @keyup.enter.native="
                handleNext(scope.row.receiptId + 'input_wei')
              "
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column property="volume" :label="td('体积 m³')" width="120px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.volume"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          property="weightS"
          :label="td('重量 kg')"
          width="120px"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.weightS"
              :ref="scope.row.receiptId + 'input_wei'"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column property="weightS" :label="td('包装')" width="120px">
          <template slot-scope="scope">
            <el-select v-model="scope.row.machiningType">
              <el-option
                v-for="dict in tf('MACHINING_TYPE')"
                :key="dict.detailId"
                :label="dict.langTranslate"
                :value="dict.detailId"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <!-- <template>
        <el-radio  label="1">木箱</el-radio>
        <el-radio  label="2">纸箱</el-radio>
      </template> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">{{ td("取 消") }}</el-button>
        <el-button type="primary" @click="updateDetail">{{
          td("确 定")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="备注"
      :visible="showRemark"
      width="30%"
      @close="closeShowRemark"
      :close-on-click-modal="false"
    >
      <!--      <el-row>-->
      <!--        <el-col :span="12">库位码: <el-input  v-model="remarkData.locationCode" ></el-input></el-col>-->
      <!--        <el-col :span="12">packSubId: <el-input  v-model="remarkData.packSubId" ></el-input></el-col>-->
      <!--      </el-row>-->
      <el-row>
        <el-col :span="24">
          <el-input
            :rows="8"
            type="textarea"
            maxlength="300"
            v-model="remarkData.remark"
          ></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRemark = false">{{ td("取 消") }}</el-button>
        <el-button type="primary" @click="updateRemark">{{
          td("确 定")
        }}</el-button>
      </span>
    </el-dialog>

    <!--库位码-->
    <el-dialog
      :visible="showConfirm"
      width="300px"
      :title="td('入库库位')"
      :close-on-click-modal="false"
      @close="closeShowConfirm"
    >
      <el-form :model="remarkData" :rules="rules" ref="locationform">
        <el-form-item :label="td('入库库位')" prop="locationCode">
          <el-input
            v-model="remarkData.locationCode"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showConfirm = false">{{ td("取 消") }}</el-button>
        <el-button
          type="primary"
          @click="updateWarehousingConFirm('locationform')"
          >{{ td("确 定") }}</el-button
        >
      </span>
    </el-dialog>

    <!--    图片上传-->
    <img-line
      v-if="showUploadImg"
      :row="uplaodRow"
      :show="showUploadImg"
      @close="
        () => {
          this.showUploadImg = false;
        }
      "
    ></img-line>

    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      label-position="top"
      style="margin-bottom: -5px;"
    >
      <el-form-item :label="td('包裹号')" prop="receiptId">
        <el-input
          v-model="queryParams.receiptId"
          :placeholder="td('请输入包裹号')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
      <el-form-item :label="td('快递单号')" prop="expressId">
        <el-input
          v-model="queryParams.expressId"
          :placeholder="td('请输入包裹单号')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
      <el-form-item :label="td('PO单号')" prop="orderId">
        <el-input
          v-model="queryParams.orderId"
          :placeholder="td('请输入PO单号')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
      <el-form-item :label="td('创建时间')">
        <el-date-picker
                v-model="createTimeScope"
                :picker-options="pickerOptions"
                size="small"
                style="width: 354px"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                range-separator="-"
                :start-placeholder="td('开始日期')"
                :end-placeholder="td('结束日期')"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="td('入库时间')">
        <el-date-picker
          v-model="timeScope"
          :picker-options="pickerOptions"
          size="small"
          style="width: 354px"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          type="datetimerange"
          range-separator="-"
          :start-placeholder="td('开始日期')"
          :end-placeholder="td('结束日期')"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" style="margin-top: 20px;margin-bottom: -20px;">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >{{ td("搜索") }}
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="primary"
          @click="resetQuery"
          >{{ td("重置") }}
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
                size="mini"
                type="primary"
                @click="exportData"
        >{{ td("导出") }}
        </el-button>
      </el-col>
    </el-row>

    <el-table-bar :fixed="true">
      <el-table
        v-loading="loading"
        :data="dataList"
        @cell-dblclick="JumpRecord"
        @selection-change="handleSelection"
      >
        <el-table-column type="selection" width="30" align="center" />
        <el-table-column
          :label="td('客户代号')"
          align="center"
          prop="cuscode"
          width="110"
        />
        <el-table-column :label="td('SO订单')" align="center" prop="soId" />
        <el-table-column
          :label="td('PO订单')"
          align="center"
          prop="orderId"
          width="110"
        />
        <el-table-column
          :label="td('快递公司')"
          align="center"
          prop="expressCom"
          width="150"
        />
        <el-table-column
          :label="td('入库网点')"
          align="center"
          prop="receSiteName"
          width="150"
        >

        </el-table-column>

        <el-table-column
          :label="td('当前网点')"
          align="center"
          prop="nowSite"
          width="150"
        />
        <el-table-column
          :label="td('轨迹')"
          align="center"
          prop="packState"
          width="150"
        >
          <template slot-scope="scope">
            <span> {{ tfp("EXPRESS_STATE", scope.row.packState) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="td('国际运费支付状态')"
          align="center"
          prop="payFinish"
          width="150"
        >
          <template slot-scope="scope">
            <span> {{ tfp("PAY_STATE", scope.row.payFinish) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="td('快递单号')"
          align="center"
          prop="expressId"
          width="150"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              {{ scope.row.expressId }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          :label="td('合包号')"
          align="center"
          prop="packNo"
          width="150"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              {{ scope.row.packNo }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          :label="td('包裹号')"
          align="center"
          prop="receiptId"
          width="150"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              {{ scope.row.receiptId }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="td('重量')" align="center" prop="weightS" />
        <el-table-column :label="td('数量')" align="center" prop="qtyInput" />
        <el-table-column :label="td('长')" align="center" prop="vlength" />
        <el-table-column :label="td('宽')" align="center" prop="vwidth" />
        <el-table-column :label="td('高')" align="center" prop="vheight" />
        <el-table-column :label="td('L*W*H')" align="center"  width="100" >
          <template slot-scope="scope">
            {{scope.row.vlength+'*'+scope.row.vwidth+'*'+scope.row.vheight}}
          </template>
        </el-table-column>
        <el-table-column :label="td('体积')" align="center" prop="volume" />
        <el-table-column :label="td('体积重')" align="center" prop="weightV" />
        <el-table-column :label="td('品名')" align="center" prop="goodsName" />
        <el-table-column :label="td('类目')" align="center" prop="goodsType">
          <template slot-scope="scope">
            {{
              scope.row.goodsType == null || scope.row.goodsType == undefined
                ? td("普货")
                : tfp("GOODS_TYPE", scope.row.goodsType)
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="td('库位')"
          align="center"
          prop="locationCode"
          width="170"
        />
        <el-table-column :label="td('状态')" align="center" prop="inStatus">
          <template slot-scope="scope">
            {{ tfp("TMS_IN_STATUS", scope.row.inStatus) }}
          </template>
        </el-table-column>
        <el-table-column :label="td('QC人')" align="center" prop="qcUser" />
        <el-table-column
          :label="td('创建时间')"
          align="center"
          prop="createTime"
          width="170"
        >
          <template slot-scope="scope">
            {{ parseTime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="td('入库时间')"
          align="center"
          prop="storeDate"
          width="170"
        >
          <template slot-scope="scope">
            {{ parseTime(scope.row.storeDate) }}
          </template>
        </el-table-column>
        <el-table-column :label="td('备注')" align="center" prop="remark" />

      </el-table>
    </el-table-bar>
	<pagination
      v-show="total > 0"
	  :total="total"
	  :page-sizes="[100, 200, 500, 1000]"
	  :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
	  />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { listCountry } from "@/api/tms/country";
import { listCustomer } from "@/api/system/customer";
import { tmsOperation } from "@/api/tms/tmsMixin";
import { getAllUserDict } from "@/api/system/user";
import {
  updateInvrntoryRemark,
  updateInvrntoryWarehousingConFirm,
  updateDetailUpdate,
  inventory,
  invrntoryPrint,
  invrntoryRemark,
  invrntoryWarehousingConFirm,
  detailUpdate,
  getGoodstypeAndGoods,
  exportData,
  inventoryNoPage
} from "@/api/tms/actionCenter";
import { sysSiteAll } from "@/api/system/SysSite";

import ImgLine from "./ImgLine.vue";
import { listFile } from "@/api/tms/file";
import { listLikeCodeAndName } from "@/api/system/customer";
export default {
  // name: 'RentManage',
  mixins: [tmsOperation],
  components: {
    ImgLine
  },

  data() {
    return {
		total:0,
      createTimeScope:null,
      timeScope:null,
      // 货品名称集合
      uplaodRow: {},
      showUploadImg: false,
      goodsTypeList: [],
      imgUrls: [],
      showImg: false,
      // 选中的的数据集合
      selection: [],
      ids: [],
      sites: [],
      // 全部用户信息
      userDictList: [],
      //国家列表
      landId: null,
      rules: {
        locationCode: [
          {
            required: true,
            message: "入库库位必填"
          }
        ]
      },
      detail: {},
      details: [],
      showConfirm: false,
      showRemark: false,
      showDetail: false,
      cusData: [],
      remarkData: {
        remark: "",
        locationCode: "",
        packSubId: "",
        receiptId: ""
      },
      ctr: new Map(),
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        customerId: null,
        ownerId: null,
        soId: null,
        orderId: null,
        expressCom: null,
        expressId: null,
        packNo: null,
        receiptId: null,
        weightS: null,
        qtyInput: null,
        goodsType: null,
        locationCode: null,
        inStatus: null,
        qcUser: null,
        createTime: null,
        remark: null,
        startTime: null,
        endTime: null
      },
      //打印禁用
      printShow: true
    };
  },
  computed:{
    pickerOptions() {
      return  {
        shortcuts: [{
          text: this.td('最近一周'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.td('最近一个月'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.td('最近三个月'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  created() {
    this.landId = Cookies.get("langId");
    // listCustomer().then(re => {
    //   this.cusData = re.rows;
    // });
    // this.getCountryOption();
    // 获取全部用户信息作为字典
    // this.getAllUserDict();
    this.initDate()
    this.getList();
    // this.getSites();

    getGoodstypeAndGoods().then(res => {
      console.log(res);
      for (let datum of res.data) {
        let option = {};
        option.value = datum.goodstypeId;
        option.label = datum.goodstypeC;
        option.children = [];
        for (let datumElement of datum.goodsList) {
          let element = {};
          element.value = datumElement.goodsId;
          element.goodsType = datumElement.goodsType;
          element.label = datumElement.goodsnameC;
          option.children.push(element);
        }
        this.goodsTypeList.push(option);
      }
      console.log(this.goodsTypeList, "商品级联框的值");
    });
  },

  watch: {
    selection(newVal) {
      newVal.length > 0 ? (this.printShow = false) : (this.printShow = true);
    },
    deep: true
  },
  methods: {
    initDate(){
      let start = new Date();
      let now = start.getFullYear() + "-" + (start.getMonth()+1 > 9 ? (start.getMonth() + 1) : "0" + (start.getMonth() + 1)) + "-" + (start.getDate() > 9 ? (start.getDate()) : "0" + (start.getDate()));
      let startTime = now+' 00:00:00'
      let endTime = now+' 11:59:59'
      this.createTimeScope=[startTime,endTime]
    },
    //导出包裹信息
   async exportData(){
      let obj = {}
      if(this.selection.length == 0){
        this.$message.info('请勾选需要导出的数据')
        return
      }
     let expressIds = this.selection.map(el=>{return el.expressId})
     let ids=''
     for (let i =0; i< expressIds.length;i++){
       if(i != expressIds.length-1){
         ids = ids+expressIds[i]+','
       }else {
         ids = ids+expressIds[i]
       }
     }
     obj.expressIds = ids
      let langId = Cookies.get("langId")

      this.download('tms/operaCenter/exportData/'+langId, {
        ...obj
      }, `包裹数据.xlsx`)
     // navigator.msSaveBlob(blob, filename);
    },
    showReceSite(id) {
      if (id != null || id != undefined) {
        let sitesValue = this.sites.filter(item => item.id == id);
        if (sitesValue.length > 0) {
          return sitesValue[0].label;
        }
      }
    },
    //获取客户代号下拉框数据
    getCusData(value) {
      if (value == "" || value == undefined || value.length < 3) return;
      let obj = {};
      obj.cusCode = value;
      listLikeCodeAndName(obj).then(res => {
        this.cusData = res.rows;
      });
    },

    // 级联框变化时调用
    goodsIdChange1(value) {
      if (value) {
        value.goodsId = value.goodsCascade[1];
      }
    },
    getSites() {
      sysSiteAll().then(res => {
        console.log(res);
        this.sites = res.data;
      });
    },
    /** 获取全部用户信息作为字典 */
    getAllUserDict() {
      getAllUserDict().then(res => {
        this.userDictList = res.data;
      });
    },
    showDetails() {
      this.showDetail = false;
    },

    //更新详情
    updateDetail() {
      console.log(this.detail);
      if (this.detail.volume == "NaN") {
        this.msgError(this.td("体积数据有误"));
        return;
      }
      updateDetailUpdate(this.detail).then(res => {
        this.showDetail = false;
        this.msgSuccess(this.td("更新成功"));
      });
    },
    //更新备注
    updateRemark() {
      let data = {};
      data.remark = this.remarkData.remark;
      data.receiptId = this.remarkData.receiptId;
      updateInvrntoryRemark(data).then(res => {
        this.showRemark = false;
        this.msgSuccess(this.td("更新成功"));
        this.getList();
      });
    },
    //更新入库确认
    updateWarehousingConFirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {};
          data.locationCode = this.remarkData.locationCode;
          data.receiptId = this.remarkData.receiptId;
          updateInvrntoryWarehousingConFirm(data).then(res => {
            this.showConfirm = false;
            this.msgSuccess(this.td("更新成功"));
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭备注弹出框
    closeShowRemark() {
      this.showRemark = false;
    },
    //关闭入库确认框
    closeShowConfirm() {
      this.showConfirm = false;
    },

    //更多
    selectRemark(row, n) {
      if (n == 1) {
        //获取备注信息
        invrntoryRemark(row).then(res => {
          this.remarkData = res.data;
          this.showRemark = true;
          this.remarkData.receiptId = row.receiptId;
        });
      } else if (n == 3) {
        //获取入库确认信息
        invrntoryWarehousingConFirm(row).then(res => {
          this.remarkData = res.data;
          this.showConfirm = true;
          this.remarkData.receiptId = row.receiptId;
        });
      } else if (n == 4) {
        this.handleDetail(row);
      } else if (n == 2) {
        this.uplaodRow = row;
        this.showUploadImg = true;

        // this.imgUrls = [];
        // let obj = {};
        // obj.expressId = row.expressId;
        // obj.recordType = "5568";

        // listFile(obj).then(res => {
        //   res.rows.forEach(el => {
        //     this.imgUrls.push(el.fileUrl);
        //   });
        //   if (res.rows.length > 0) {
        //     this.showImg = true;
        //   } else {
        //     this.$message.info("未上传图片");
        //   }
        // });
      }
    },
    getCountryOption: function() {
      listCountry().then(response => {
        response.rows.forEach(e => {
          this.ctr.set(e.countryId, e.country);
        });
        console.log(this.ctr);
      });
    },
    //打印二维码
    // async printErcode(row) {
    //   let LODOP = getLodop();
    //   LODOP.PRINT_INIT(this.td("集包打印标签")); //调用getLodop获取LODOP对象
    //   //LODOP.SET_PRINT_PAGESIZE(1, '100mm', '70mm', "");
    //   LODOP.SET_PRINT_PAGESIZE(1, "100mm", "75mm", "");
    //   LODOP.SET_PRINT_STYLE("FontSize", 10);
    //   const dw = tf("GOODS_TYPE");
    //   const dc = tf("TRANSPORT_CHANNEL");
    //   var dict = new Map();
    //   for (const dataItem of dw) {
    //     dict.set(dataItem.detailId, dataItem.langTranslate);
    //   }
    //   for (const dcItem of dc) {
    //     dict.set(dcItem.detailId, dcItem.langTranslate);
    //   }
    //   /* 调用打印接口 */
    //   let langId = Cookies.get("langId"); // 国家id
    //   let cda = await invrntoryPrint(row.receiptId, langId).then(res => {
    //     return res.data;
    //   });
    //   for (let key in cda) {
    //     // console.log(key + '---' +obj[key]);
    //     if (cda.hasOwnProperty(key)) {
    //       // console.log(key + '---' +cda[key]);
    //       cda[key] = cda[key] == null ? "" : cda[key];
    //     }
    //   }
    //
    //   console.log(cda);
    //   // cda.channel = cda.channel == null ? "" : cda.channel;
    //   // cda.locationCode = cda.locationCode == null ? "" : cda.locationCode;
    //   // cda.companyName = cda.companyName == null?"":cda.companyName
    //   // cda.receiptId = cda.receiptId == null?"":cda.receiptId
    //   // cda.cuscode = cda.cuscode == null?"":cda.cuscode
    //   let channel = "";
    //   if (cda.channelCode == "101") {
    //     channel = "EK";
    //   } else if (cda.channelCode == "102") {
    //     channel = "SEA";
    //   } else if (cda.channelCode == "103") {
    //     channel = "PFC";
    //   }
    //   cda.companyName = cda.companyName == null ? "" : cda.companyName;
    //   LODOP.NewPage();
    //   LODOP.ADD_PRINT_BARCODE(
    //     "5mm",
    //     "5mm",
    //     "98mm",
    //     "15mm",
    //     "128B",
    //     cda.receiptId
    //   );
    //   let strHtml =
    //     "<table style='border-collapse: collapse; ' width='338' height='185' border='1'>" +
    //     "<tr height='30'>" +
    //     "<td width='50%' align='center'>" +
    //     cda.cuscode +
    //     "</td>" +
    //     "<td colspan='3' align='center'>" +
    //     cda.companyName +
    //     "</td>" +
    //     "</tr>" +
    //     "<tr height='31'>" +
    //     "<td colspan='3' align='center'>" +
    //     cda.expressId +
    //     "</td>" +
    //     "<td width='20%' rowspan='2' align='center' style='font-size: 40px;'>" +
    //     cda.goodsTypes +
    //     "</td>" +
    //     "</tr>" +
    //     "<tr height='32'>" +
    //     "<td colspan='2' align='center'>" +
    //     "<span>" +
    //     cda.locationCode +
    //     "(" +
    //     "<span>" +
    //     cda.storeSite +
    //     "</span>" +
    //     ")" +
    //     "</span>" +
    //     "</td>" +
    //     "<td width='20%'>" +
    //     cda.countryId +
    //     "-" +
    //     channel +
    //     "</td>" +
    //     "</tr>" +
    //     "<tr height='70'>" +
    //     "<td colspan='3' style='font-size: 14px; overflow: visible;'>" +
    //     "<span style='display: inline-block; width: 50%; margin:2px;'>" +
    //     cda.addressee +
    //     "</span>" +
    //     "<span style='display: inline-block; width: 44%; '>" +
    //     cda.addresseeTel +
    //     "</span>" +
    //     "<div style=' width:270px;word-wrap:break-word; margin:2px 2px 5px 5px;  '>" +
    //     cda.addresseeAdd +
    //     "</div>" +
    //     "</td>" +
    //     "<td width='20%' style='font-size: 40px;' align='center'>" +
    //     cda.packArea +
    //     "</td>" +
    //     "</tr>" +
    //     "</table>";
    //   LODOP.ADD_PRINT_HTM("21mm", "5mm", "100%", "100%", strHtml);
    //
    //   LODOP.PREVIEW(); // 弹框
    // },
    /** 批量打印 */
    // printErcodeBatch1(){
    //   LODOP.PRINT_INIT("打印标签"); //调用getLodop获取LODOP对象
    //   LODOP.SET_PRINT_PAGESIZE(1, '100mm', '75mm', "");
    //   LODOP.SET_PRINT_STYLE("FontSize", 12);
    //   LODOP.ADD_PRINT_BARCODE("5mm", "5mm", "92mm", "11mm", '128B', "CX23022000035")
    //   let strHtml = "<table style='border-collapse: collapse; font-size:14px;width:90mm' width='300' height='155' border='1'>"+
    //     "<tr height='27'>"+"<td width='50%' align='center'>"+ 'it.cuscode' +"</td>"+"<td colspan='3' align='center'>"+'it.companyName'+"</td>"+"</tr>"+
    //     "<tr height='27'>"+"<td colspan='3' align='center' style='font-size: 12px;'>"+ 'it.expressId' +"</td>"+
    //     "<td width='20%' rowspan='2' align='center' style='font-size: 35px;'>"+"P"+"</td>"+"</tr>"+
    //     "<tr height='29'>"+"<td colspan='2' align='center' style='font-size: 12px;'>"+"524564" + '(' +"45165"+ ')' + "</td>"+"<td width='20%'>"+ "6666" + "-" + "55555" +"</td>"+"</tr>"+
    //     "<tr height='50'>"+"<td colspan='3' style='font-size: 12px; overflow: visible;'>"+
    //     "<span style='display: inline-block; width: 50%;margin:2px;  font-size:12px '>"+ "2152156" +"</span>"+
    //     "<span style='display: inline-block; width: 44%;  font-size:12px '>"+ "5241564" +"</span>"+
    //     "<div style='width:273px;word-wrap:break-word; margin:2px 2px 5px 5px;  font-size:12px '>"+ " 4161661654416165446165441616544161654" +"</div>"+
    //     "</td>"+"<td width='20%' style='font-size: 35px;' align='center'>" + "T" +"</td>"+"</tr>"+
    //     "</table>"
    //   LODOP.ADD_PRINT_HTM("17mm",'5mm',"90mm","55mm",strHtml);
    //   LODOP.ADD_PRINT_BARCODE("59mm", "5mm", "92mm", "11mm", '128B', "CX23022000035")

    //   LODOP.PREVIEW() // 弹框
    // },

    // async printErcodeBatch() {
    //   let LODOP = getLodop();
    //   LODOP.PRINT_INIT(this.td("集包打印标签")); //调用getLodop获取LODOP对象
    //   //LODOP.SET_PRINT_PAGESIZE(1, '100mm', '70mm', "");
    //   LODOP.SET_PRINT_PAGESIZE(1, "100mm", "75mm", "");
    //   LODOP.SET_PRINT_STYLE("FontSize", 10);
    //   const dw = tf("GOODS_TYPE");
    //   const dc = tf("TRANSPORT_CHANNEL");
    //   var dict = new Map();
    //   for (const dataItem of dw) {
    //     dict.set(dataItem.detailId, dataItem.langTranslate);
    //   }
    //   for (const dcItem of dc) {
    //     dict.set(dcItem.detailId, dcItem.langTranslate);
    //   }
    //   for (let row of this.selection) {
    //     LODOP.NewPage();
    //     /* 调用打印接口 */
    //     let langId = Cookies.get("langId"); // 国家id
    //     let cda = await invrntoryPrint(row.receiptId, langId).then(res => {
    //       return res.data;
    //     });
    //     for (let key in cda) {
    //       // console.log(key + '---' +obj[key]);
    //       if (cda.hasOwnProperty(key)) {
    //         // console.log(key + '---' +cda[key]);
    //         cda[key] = cda[key] == null ? "" : cda[key];
    //       }
    //     }
    //     // cda.channel = cda.channel == null ? "" : cda.channel;
    //     // cda.locationCode = cda.locationCode == null ? "" : cda.locationCode;
    //     let channel = "";
    //     if (cda.channelCode == "101") {
    //       channel = "EK";
    //     } else if (cda.channelCode == "102") {
    //       channel = "SEA";
    //     } else if (cda.channelCode == "103") {
    //       channel = "PFC";
    //     }
    //     cda.companyName = cda.companyName == null ? "" : cda.companyName;
    //     LODOP.ADD_PRINT_BARCODE(
    //       "5mm",
    //       "5mm",
    //       "98mm",
    //       "15mm",
    //       "128B",
    //       cda.receiptId
    //     );
    //     let strHtml =
    //       "<table style='border-collapse: collapse; ' width='338' height='185' border='1'>" +
    //       "<tr height='30'>" +
    //       "<td width='50%' align='center'>" +
    //       cda.cuscode +
    //       "</td>" +
    //       "<td colspan='3' align='center'>" +
    //       cda.companyName +
    //       "</td>" +
    //       "</tr>" +
    //       "<tr height='31'>" +
    //       "<td colspan='3' align='center'>" +
    //       cda.expressId +
    //       "</td>" +
    //       "<td width='20%' rowspan='2' align='center' style='font-size: 40px;'>" +
    //       cda.goodsTypes +
    //       "</td>" +
    //       "</tr>" +
    //       "<tr height='32'>" +
    //       "<td colspan='2' align='center'>" +
    //       "<span>" +
    //       cda.locationCode +
    //       "(" +
    //       "<span>" +
    //       cda.storeSite +
    //       "</span>" +
    //       ")" +
    //       "</span>" +
    //       "</td>" +
    //       "<td width='20%'>" +
    //       cda.countryId +
    //       "-" +
    //       channel +
    //       "</td>" +
    //       "</tr>" +
    //       "<tr height='70'>" +
    //       "<td colspan='3' style='font-size: 14px; overflow: visible;'>" +
    //       "<span style='display: inline-block; width: 50%; margin:2px;'>" +
    //       cda.addressee +
    //       "</span>" +
    //       "<span style='display: inline-block; width: 44%; '>" +
    //       cda.addresseeTel +
    //       "</span>" +
    //       "<div style='width:270px;word-wrap:break-word; margin:2px 2px 5px 5px;  '>" +
    //       cda.addresseeAdd +
    //       "</div>" +
    //       "</td>" +
    //       "<td width='20%' style='font-size: 40px;' align='center'>" +
    //       cda.packArea +
    //       "</td>" +
    //       "</tr>" +
    //       "</table>";
    //     LODOP.ADD_PRINT_HTM("21mm", "5mm", "100%", "100%", strHtml);
    //   }
    //   LODOP.PREVIEW(); // 弹框
    // },
    getList() {
      this.loading = true;
      if ( (this.createTimeScope??'')!=='') {
        this.queryParams.startTime = this.createTimeScope[0];
        this.queryParams.endTime = this.createTimeScope[1];
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
        this.$message.info('请选择创建日期!')
        this.loading = false;
        return;
      }
      if ( (this.timeScope??'')!=='') {
        this.queryParams.startSignTime = this.timeScope[0];
        this.queryParams.endSignTime = this.timeScope[1];
      } else {
        this.queryParams.startSignTime = null;
        this.queryParams.endSignTime = null;
      }
      this.queryParams.langId = this.landId;
      // console.log(this.queryParams,'this.queryParams')
      inventoryNoPage(this.queryParams).then(response => {
		  // console.log(response,"@@@@@");
        this.dataList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },

    // 计算体积
    calculateV(row, n) {
      if (n == 1) {
        row.volume = (
          (row.vlength * row.vwidth * row.vheight) /
          1000000
        ).toFixed(4);
      }
      this.detail = row;
    },
    handleNext(input) {
      this.$nextTick(() => {
        this.$refs[input].focus();
      });
    },

    handleDetail(row) {
      detailUpdate(row).then(res => {
        if (res.data.machiningType == "0") {
          res.data.machiningType = "";
        }
        res.data.goodsCascade = [];
        res.data.goodsCascade.push(res.data.goodstypeId * 1);
        // res.data.goodsName.push( res.data.goodsId+'');
        res.data.goodsCascade.push(res.data.goodsId + "");
        // res.data.machiningType = ''
        let data = [];
        data.push(res.data);
        // data[0].machiningType == "0"? " ":data[0].machiningType

        this.detail = res.data;
        this.details = data;
        console.log(this.details, "details");
        this.showDetail = true;
      });
    },
    //点击跳转跟踪记录
    JumpRecord(row, column, cell, event) {
      if (column.property == "expressId") {
        this.$router.push({
          path: "/tms/mycus/track",
          query: {
            expressId: row.expressId
          }
        });
      } else if (column.property == "receiptId") {
        this.$router.push({
          path: "/tms/mycus/track",
          query: {
            expressId: row.receiptId
          }
        });
      } else if (column.property == "packNo") {
        this.$router.push({
          path: "/tms/mycus/track",
          query: {
            expressId: row.packNo
          }
        });
      }
    },
    // 多选框选中数据
    handleSelection(selection) {
      this.ids = selection.map(item => item.packMergeId);
      this.selection = selection;
    }
  }
};
</script>
<style  scoped>
::v-deep .elTableBar {
  margin-top: 20px !important;
}

::v-deep .el-scrollbar__thumb {
  height: 15px !important;
  background-color: #534840;
}

::v-deep .el-scrollbar__bar {
  height: 15px !important;
}

::v-deep .el-scrollbar__bar.is-horizontal > div {
  height: 15px !important;
  margin: 5px;
}
</style>
